import React, { Component } from 'react';
import ContestDropdown from '../contest_dropdown/ContestDropdown';

import { withLocalize, Translate } from 'react-localize-redux';
import ContestTranslation from '../../../../../translations/contest_rules.json';

import { getBaseName } from '../../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Contests extends Component { 

    constructor(props) {
        super(props);

        this.props.addTranslation(ContestTranslation);
    }

    render() {
        return (
            <div className="generic-wrapper">

                <Helmet>
                    <title>Contest Rules | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="This page provides links to the rules for all contests currently offered at Ctrl V virtual reality arcades."/>
                    <link rel="canonical" href={`${getBaseName()}/contests/`} />
                    <meta property="og:title" content="Current Contest Rules | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="This page provides links to the rules for all contests currently offered at Ctrl V virtual reality arcades." />
                    <meta property="og:url" content={`${getBaseName()}/contest/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="This page provides links to the rules for all contests currently offered at Ctrl V virtual reality arcades." />
                    <meta name="twitter:title" content="Contest Rules | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="contests,rules,promotions" />
                </Helmet>

                {/* Hide this if contests are running */}
                {/* { <Translate>
                    {({ translate }) => (
                        <ContestDropdown>
                            title={translate("no-contests")}
                        </ContestDropdown>
                    )}
                </Translate> } */}

                <Translate>
                    {({ translate }) => (
                        <ContestDropdown 
                            title={translate("guelph-superhot-tournament.title")}
                            body={(
                                <div className="generic-wrapper blue-sub-headings align-left">
                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-1.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-1.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-1.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-2.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-4.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-4.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <ol>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-1" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-2" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-3" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-4" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-5" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-6" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-7" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-8" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-9" /></li>
                                            <li><Translate id="guelph-superhot-tournament.section-4.body-2.list.item-10" /></li>
                                        </ol>
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-4.body-3" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-5.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-5.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-6.title" />
                                    </div>
                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-6.body-1.heading" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-6.body-1.pre-list-line-1" />
                                        <ul>
                                            <li><Translate id="guelph-superhot-tournament.section-6.body-1.list-1.item-1" /></li>
                                        </ul>
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-6.body-1.pre-list-line-2" />
                                        <ul>
                                            <li><Translate id="guelph-superhot-tournament.section-6.body-1.list-2.item-1" /></li>
                                        </ul>
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-6.body-1.pre-list-line-3" />
                                        <ul>
                                            <li><Translate id="guelph-superhot-tournament.section-6.body-1.list-3.item-1" /></li>
                                        </ul>
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-7.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-7.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-8.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-8.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="guelph-superhot-tournament.section-9.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="guelph-superhot-tournament.section-9.body-1" />
                                    </div>
                                </div>
                            )} 
                        />
                    )}
                </Translate>

                {/* <Translate>
                    {({ translate }) => (
                        <ContestDropdown 
                            title={translate("plano-elven-tournament.title")}
                            body={(
                                <div className="generic-wrapper blue-sub-headings align-left">
                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-1.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-1.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-1.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-2.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-3.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-3.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-4.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-4.body-1.pre-list-line" />
                                    </div>
                                    <div className="generic-section">
                                        <ol>
                                            <li><Translate id="plano-elven-tournament.section-4.body-1.list.item-1" /></li>
                                            <li><Translate id="plano-elven-tournament.section-4.body-1.list.item-2" /></li>
                                        </ol>
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-4.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-5.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-5.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-6.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-6.body-1.pre-list-line" />
                                        <ul>
                                            <li><Translate id="plano-elven-tournament.section-6.body-1.list.item-1" /></li>
                                        </ul>
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-7.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-7.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-8.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-8.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="plano-elven-tournament.section-9.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="plano-elven-tournament.section-9.body-1" />
                                    </div>
                                </div>
                            )} 
                        />
                    )}
                </Translate> */}
            </div>
        );
    }
}

export default withLocalize(Contests);