import React, { useState } from "react";
import "./OpeningSoonPopup.css";
import DynamicImg from "../dynamic_image/DynamicImg";

const OpeningSoonImg = "https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/trevr_celebrate";

function OpeningSoonPopup () {
    const [open, setOpen] = useState(true);
    
    function handleClick() {
          setOpen(!open);
    }

    return (open === true ? 
        <div className = "openBackdrop">
            <div className = "openPositionWrapper">
                <div className = "openMain">
                    <span className = "openText1">
                        <div>GRAND OPENING</div>
                        <div>FEB 15, 2025</div>
                    </span>
                    <a href = "https://www.eventbrite.ca/e/ctrl-v-cambridge-grand-opening-tickets-1113102833539?aff=oddtdtcreator" className = "openImage">
                        <DynamicImg  
                            imageName={OpeningSoonImg}
                            fallbackExt='png'
                            alt="TreVr Celebrating"
                            width="100%"
                            height="auto"
                        />
                    </a>
                    <span className = "openText2">
                        <a href="https://www.eventbrite.ca/e/ctrl-v-cambridge-grand-opening-tickets-1113102833539?aff=oddtdtcreator"><span className = "blueText">Click Here to Register</span></a>
                        <div>Free VR All Day!</div>
                    </span>
                    <div className = "openCloseButton" onClick = {handleClick}>X</div>
                </div>
            </div>
        </div> : null
    );  
}

export default OpeningSoonPopup;